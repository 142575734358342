import React, { useState } from "react";
import { Dialog } from "./Dialog";
import Well from "./Well";
import { Attestation } from "./Attestation";
import { ConfirmOrCancel } from "./ConfirmOrCancel";
import { getSigningProvider } from "../utils/Utils";
import { Contract, utils } from "ethers";
import { EASContractAddress } from "../utils/config";
import EASabi from "../abis/EASabi.json";
import { LoadingForDialog } from "./LoadingForDialog";

export function AttestationRevokeDialog({ attestationData, onClose }) {
  const [loading, setLoading] = useState(false);

  const styles = {
    attestationHolder: {
      // display: 'flex'
    },
    table: {
      width: "100%",
    },
    controls: {
      marginTop: 8,
    },
  };
  return (
    <Dialog
      title={"Revoke attestation"}
      body={
        <div>
          <Well isError={true}>
            This will revoke the following attestation. This cannot be undone!
          </Well>
          <div style={styles.attestationHolder}>
            <table
              border={0}
              style={styles.table}
              cellPadding={8}
              cellSpacing={0}
            >
              <tbody>
                <Attestation
                  attestationData={attestationData}
                  whichAddress={"to"}
                />
              </tbody>
            </table>
          </div>
          <div style={styles.controls}>
            {!loading ? (
              <ConfirmOrCancel
                onCancel={onClose}
                onConfirm={async () => {
                  const signer = getSigningProvider();

                  const contract = new Contract(
                    EASContractAddress,
                    EASabi,
                    signer
                  );

                  try {
                    console.log('rev',attestationData.UUID)
                    const tx = await contract.revoke(attestationData.UUID);

                    setLoading(true);

                    setTimeout(async () => {
                      await tx.wait();

                      setLoading(false);
                      onClose();
                    }, 50);
                  } catch (e) {
                    console.log("txerror", e);
                  }
                }}
              />
            ) : (
              <LoadingForDialog />
            )}
          </div>
        </div>
      }
      isVisible={true}
      onClose={onClose}
    />
  );
}
