import React from "react";
import { primary15, primary45 } from "../utils/colors";
import {AORegistryItem} from "./AORegistryItem";

export default function AORegistryTable({
  aos,
  noHeader
}) {
  const styles = {
    table: {
      width: "100%",
    },
    tableHeader: {
      backgroundColor: primary15,
      color: primary45,
      borderRadius: 4,
      fontSize: 14,
      fontWeight: 600,
      borderBottom: "1px solid #000",
    },
    headerDate: {
      textAlign: "right",
      borderRadius: "0 0 4px 0",
    },
    headerAO: {
      borderRadius: "0 0 0 4px",
      textAlign: 'left'
    },
    verifier: {
      textAlign: 'center'
    }
  };

  if (aos === null) {
    return null;
  }

  return (
    <>
      <table
        style={{ ...styles.table, marginBottom: 8 }}
        cellPadding={8}
        cellSpacing={0}
        border={0}
      >
        <tbody>
          {!noHeader ? (
            <>
              <tr style={styles.tableHeader}>
                <td style={styles.headerAO}>AO ID</td>
                <td style={styles.verifier}>Verifier</td>
                <td style={styles.headerDate}>Schema</td>
              </tr>
            </>
          ) : null}
        </tbody>
      </table>
      <table style={styles.table} cellPadding={8} cellSpacing={0} border={0}>
        <tbody>
          {aos.map((ao, index) => (
            <AORegistryItem
              key={index}
              aoData={ao}
            />
          ))}
        </tbody>
      </table>
    </>
  );
}
