import React, { useState } from "react";
import { Dialog } from "./Dialog";
import { TextInput } from "./TextInput";
import { ConfirmOrCancel } from "./ConfirmOrCancel";
import { getSigningProvider } from "../utils/Utils";
import { Contract } from "ethers";
import { AORegistryContractAddress } from "../utils/config";
import AORegistryAbi from "../abis/AORegistryAbi.json";
import { LoadingForDialog } from "./LoadingForDialog";
import {ethers} from "ethers/lib.esm";

export default function RegisterAODialog({ onClose }) {
  const [loading, setLoading] = useState(false);
  const [verifierAddress, setVerifierAddress] = useState('');
  const [schema, setSchema] = useState('');

  return (
    <Dialog
      title={"Register new AO"}
      isVisible={true}
      body={
        <div>
          <TextInput
            title={"Verifier contract address"}
            placeholder={"0x000000..."}
            value={verifierAddress}
            onChange={event=>setVerifierAddress(event.target.value)}
          />
          <TextInput
            title={"Schema"}
            placeholder={"ex: bytes32 hash,address userAddress,uint8 value"}
            istextarea={'true'} // string because stupid html
            rows={4}
            value={schema}
            onChange={event=>setSchema(event.target.value)}
          />

          {!loading ? (
            <ConfirmOrCancel
              confirmTitle={"Register"}
              onCancel={onClose}
              onConfirm={async () => {
                try {
                  const provider = getSigningProvider();
                  const contract = new Contract(
                    AORegistryContractAddress,
                    AORegistryAbi,
                    provider
                  );

                  let tmpAddress = verifierAddress;

                  if (!ethers.utils.isAddress(verifierAddress)) {
                    tmpAddress = ethers.constants.AddressZero;
                  }

                  const tx = await contract.register(ethers.utils.toUtf8Bytes(schema), tmpAddress);
                  setLoading(true);

                  await tx.wait();
                  setLoading(false);
                  onClose();
                } catch(e) {
                  console.log('invalid', e)
                }



              }}
            />
          ) : (
            <LoadingForDialog />
          )}
        </div>
      }
      onClose={onClose}
    />
  );
}
