import React from "react";
import { primary2, primary3, primary4 } from "../utils/colors";
import { FaSpinner } from "react-icons/fa";

export function LoadingForDialog({
  text = "Waiting for confirmation...",
  position = "flex-end",
}) {
  const styles = {
    container: {
      display: "flex",
      justifyContent: position,
      alignItems: "center",
      color: primary4,
    },
    spinner: {
      marginRight: 4,
      height: 14,
      width: 14,
    },
  };
  return (
    <div style={styles.container}>
      <FaSpinner style={styles.spinner} className={"rotating"} />
      {text}
    </div>
  );
}
