import { TextInput } from "./TextInput";
import React from "react";
import {primary45} from "../utils/colors";
import Switch from "react-switch";

export function AutoInput({ input, onChange }) {
  const { value, type, name } = input;

  const styles ={
    name: {
      fontSize: 14,
      color: primary45,
      marginRight: 8,
    },
    inline: {
      display: 'flex',
      alignItems: 'center'
    }
  }

  switch (type) {
    case "bool":
      return (
        <div style={styles.inline}>
          <span style={styles.name}>{name}: </span>
          <Switch
            checked={!!value}
            type={"checkbox"}
            onChange={(checked) => onChange(checked)}
          />
        </div>
      );
    case "uint8":
    case "uint16":
    case "uint24":
    case "uint32":
    case "uint40":
    case "uint48":
    case "uint56":
    case "uint64":
    case "uint72":
    case "uint80":
    case "uint88":
    case "uint96":
    case "uint104":
    case "uint112":
    case "uint120":
    case "uint128":
    case "uint136":
    case "uint144":
    case "uint152":
    case "uint160":
    case "uint168":
    case "uint176":
    case "uint184":
    case "uint192":
    case "uint200":
    case "uint208":
    case "uint216":
    case "uint224":
    case "uint232":
    case "uint240":
    case "uint248":
    case "uint256":
    case "bytes":
    case "bytes32":
      return (
        <TextInput
          placeholder={name}
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
      );
      break;
    default:
      return null;
  }
}
