/* istanbul ignore file */

// main actions
export const main = {
  ADD_INSTANCE: 'ADD_INSTANCE',
};

// tempData actions
export const tempData = {
  SET_USD_PRICE: 'SET_USD_PRICE',

};
