import React from "react";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { errorColor, goodColor } from "../utils/colors";
import { RatingIndicator } from "./RatingIndicator";
import { ethers } from "ethers";
import { attestationTypes } from "../attestationTypes";

export function AODisplay({ ao, data }) {
  const styles = {
    ao: {
      textAlign: "left",
      display: "flex",
      alignItems: "center",
    },
    icon: {
      width: 18,
      height: 18,
      marginLeft: 4,
    },
  };

  const type = attestationTypes[ao];

  const coder = ethers.utils.defaultAbiCoder;

  let decodedObj = {};
  try {
    // const schemaArr = ['bool'];
    const schemaArr = type.schema.split(",");
    const decoded = coder.decode(schemaArr, data);
    decoded.forEach((val, i) => {

      const propertyName = schemaArr[i].split(' ')[1];
      if (!propertyName) {
        decodedObj = null;
        throw new Error('MissingPropertyNamesFromSchema');
      }
      return (decodedObj[propertyName] = val)
    });
  } catch (e) {
    decodedObj = null
    console.log("Error decoding!", e);
  }

  if (!decodedObj) {
    return null;
  }

  return (
    <div>
      {ao === 2 ? (
        <div style={styles.ao}>
          {decodedObj.value ? (
            <FaThumbsUp color={goodColor} style={styles.icon} />
          ) : (
            <FaThumbsDown style={styles.icon} color={errorColor} />
          )}
        </div>
      ) : null}
      {ao === 3 ? (
        <div style={styles.ao}>
          <RatingIndicator value={decodedObj.value} />
        </div>
      ) : null}
    </div>
  );
}
