import React from "react";
import { cleanBorder, primary15, primary2, primary45 } from "../utils/colors";
import { useIsSmallScreen } from "../hooks/useIsSmallScreen";

export function OverviewBlock({ value, title, smallTitle, color, onClick }) {
  const isSmallScreen = useIsSmallScreen();

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: primary15,
      color: primary45,
      borderRadius: 8,
      padding: 8,
      border: cleanBorder,
      cursor: 'pointer',
    },
    padding: {
      padding: 4,
      width: isSmallScreen ? 80 : 186,
    },
    value: {
      fontSize: 20,
      fontWeight: 600,
    },
    title: {
      fontSize: 14,
      fontWeight: 600,
    },
  };
  return (
    <div style={styles.padding}>
      <div style={styles.container} onClick={onClick}>
        <div style={{ ...styles.value, color: color }}>{value}</div>
        <div style={{ ...styles.title, color: color }}>
          {isSmallScreen ? smallTitle : title}
        </div>
      </div>
    </div>
  );
}
