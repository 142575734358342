import { AttestationTypeBlock } from "./AttestationTypeBlock";
import React from "react";
import { attestationTypes } from "../attestationTypes";
import AO_Custom from "./attestationDialogs/AO_Custom";
import {FaEdit} from 'react-icons/fa';

export function AttestationBlockList({attestToAddress}) {
  const styles = {
    attestationTypes: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      // padding: 12,
    },
  };

  return (
    <div style={styles.attestationTypes}>
      {attestationTypes.map((attestationType) =>
      !attestationType.hidden?<AttestationTypeBlock
          key={attestationType.title}
          attestToAddress={attestToAddress}
          title={attestationType.title}
          description={attestationType.description}
          attestDialog={attestationType.attestDialog}
          iconComponent={attestationType.iconComponent}
        />:null
      )}
      <AttestationTypeBlock
        attestToAddress={attestToAddress}
        title={'Custom'}
        description={'Add a custom attestation'}
        attestDialog={AO_Custom}
        iconComponent={FaEdit}
      />
    </div>
  );
}
