import React, { useState } from "react";
import { Pill } from "./Pill";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { goodColor, primary15, primary4 } from "../utils/colors";
import useHover from "../hooks/useHover";
import { useIsSmallScreen } from "../hooks/useIsSmallScreen";
import { navigateToAddress } from "../utils/Utils";
import { attestationTypes } from "../attestationTypes";
import { ethers } from "ethers";
import { AttestationRevokeDialog } from "./AttestationRevokeDialog";
import { MiniAttestation } from "./MiniAttestation";
import AttestationDialog from "./AttestationDialog";
import { AODisplay } from "./AODisplay";
import { FaArrowDown } from "react-icons/fa";
import { AOPill } from "./AOPill";

dayjs.extend(relativeTime);



export function Attestation({
  attestationData,
  whichAddress,
  account,
  showMiniFrom,
  showDetails,
}) {
  const [hoverRef, isHovered] = useHover();
  const isSmallScreen = useIsSmallScreen();
  const [revokeDialogVisible, setRevokeDialogVisible] = useState(false);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  const {
    ao,
    to,
    from,
    time,
    expirationTime,
    revocationTime,
    data,
    refUUID,
    uuid,
  } = attestationData;
  const currentTime = ethers.BigNumber.from(dayjs().unix());
  const isExpired = ethers.BigNumber.from(expirationTime).lt(currentTime);
  const isRevoked = !ethers.BigNumber.from(revocationTime).isZero();

  const styles = {
    container: {
      backgroundColor:
        isRevoked || isExpired ? "#fff5f5" : isHovered ? primary15 : null,
      color: primary4,
      cursor: "pointer",
    },
    ao: {
      textAlign: "left",
      alignItems: "center",
    },
    aoContainer: {
      minWidth: 58,
    },

    time: {
      textAlign: "right",
    },
    address: {
      fontFamily: "Roboto Mono",
      textOverflow: "ellipsis",
      textAlign: "center",
      overflow: "hidden",
      width: isSmallScreen ? 100 : null,
    },

    pillWithIcon: {
      display: "inline-flex",
      alignItems: "center",
    },
    attestationDetails: {
      padding: 4,
    },
    revoked: {
      color: "#c77e7e",
      fontWeight: 600,
      fontSize: 14,
      padding: 4,
    },
    miniFrom: {
      marginBottom: 8,
    },
    arrow: {
      padding: 8,
      paddingTop: 12,
    },
  };

  const aoTitle = attestationTypes?.[ao]?.title;

  const addressToDisplay = whichAddress === "from" ? from : to;

  const timeSinceStr = dayjs().to(dayjs.unix(ethers.BigNumber.from(time)));

  return (
    <>
      <tr
        style={styles.container}
        ref={hoverRef}
        className={showDetails ? "noBorder" : "borderBottom"}
        onClick={() => setDetailsDialogOpen(true)}
      >
        <td style={styles.ao}>
          <div style={styles.aoContainer}>
            {detailsDialogOpen ? (
              <AttestationDialog
                onClose={(e) => {
                  e.stopPropagation();
                  setDetailsDialogOpen(false);
                }}
                UUID={uuid}
                attestationData={attestationData}
                whichAddress={whichAddress}
              />
            ) : null}
            {revokeDialogVisible ? (
              <AttestationRevokeDialog
                attestationData={attestationData}
                onClose={() => setRevokeDialogVisible(false)}
              />
            ) : null}
            {isRevoked ? <div style={styles.revoked}>Revoked</div> : null}
            <div style={styles.pillWithIcon}>
              <AOPill ao={ao}/>
              <AODisplay
                ao={ethers.BigNumber.from(ao).toNumber()}
                data={data}
              />
            </div>
            {/*<div style={styles.attestationDetails}>*/}
            {/*  <AttestationDetail title={"ID"} value={UUID.substr(2, 6)} />*/}
            {/*  <AttestationDetail*/}
            {/*    title={"Type"}*/}
            {/*    value={aoTitle ? aoTitle : "Unknown"}*/}
            {/*  />*/}
            {/*  <div style={styles.tools}>*/}
            {/*    {account === from.toLowerCase() && !isRevoked ? (*/}
            {/*      <TextButton*/}
            {/*        title={"Revoke"}*/}
            {/*        color={"#fdacac"}*/}
            {/*        onClick={(event) => {*/}
            {/*          setRevokeDialogVisible(true)*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    ) : null}*/}

            {/*    {refUUID === ethers.constants.HashZero ? (*/}
            {/*      <div>*/}
            {/*        <AttestToAttestationButton*/}
            {/*          UUID={UUID}*/}
            {/*          value={true}*/}
            {/*          title={"Affirm"}*/}
            {/*        />*/}
            {/*        <AttestToAttestationButton*/}
            {/*          UUID={UUID}*/}
            {/*          value={false}*/}
            {/*          title={"Refute"}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    ) : null}*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </td>
        <td>
          <div style={styles.address}>
            {refUUID !== ethers.constants.HashZero ? (
              <>
                {showMiniFrom ? (
                  <div style={styles.miniFrom}>{from}</div>
                ) : null}
                <MiniAttestation UUID={refUUID} />
              </>
            ) : (
              <div>
                {showDetails ? (
                  <div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateToAddress(from);
                      }}
                    >
                      {from}
                    </div>
                    <div style={styles.arrow}>
                      <FaArrowDown color={goodColor} />
                    </div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateToAddress(to);
                      }}
                    >
                      {to}
                    </div>
                  </div>
                ) : (
                  <div>
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateToAddress(addressToDisplay);
                      }}
                    >
                      {addressToDisplay}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </td>

        <td style={styles.time}>{timeSinceStr}</td>
      </tr>
    </>
  );
}
