import React from "react";
import {cleanBorder, primary15, primary2, primary3, primary45} from "../utils/colors";

export function Tab({ title, selected, onClick }) {
  const styles = {
    container: {
      backgroundColor: selected? primary3: null,
      display: 'inline-block',
      padding: '6px 10px',
      border: `1px solid ${primary2}`,
      borderRadius: '4px 4px 0 0',
      borderBottom: 0,
      color: selected?'#FFF':primary45,
      marginBottom: 0,
      marginRight: 4,
      userSelect: 'none',
      cursor: 'pointer',
      fontSize: 14,
    }
  }
  return <div style={styles.container} onClick={onClick}>{title}</div>;
}
