import { BigButton } from "./BigButton";
import React from "react";

export function ButtonWithIcon({ onClick, title, iconComponent, style, size=12 }) {
  const IconComponent = iconComponent;
  const styles = {
    attestIcon: {
      marginRight: 4,
      width: size,
      height: size,
    },
    attestButton: {
      display: "flex",
      alignItems: "center",
    },
  }
  return (
    <BigButton
      title={
        <div style={styles.attestButton}>
          <IconComponent style={styles.attestIcon} />
          {title}
        </div>
      }
      style={style}
      onClick={onClick}
    />
  );
}
