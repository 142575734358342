import React from "react";
import { cleanBorder, primary45 } from "../utils/colors";

export function TextInput(props) {
  const { title, istextarea } = props;

  const styles = {
    input: {
      width: '100%',
      padding: 8,
      borderRadius: 4,
      border: cleanBorder,
      boxSizing: 'border-box',
      color: primary45
    },
    title: {
      color: primary45,
      fontSize: 14,
      marginBottom: 4,
      fontWeight: 600
    },
    container: {
      marginBottom: 8,
    }
  };
  return (
    <div style={styles.container}>
      <div style={styles.title}>{title}</div>
      {istextarea ? (
        <textarea className={"textInput"} style={styles.input} {...props} />
      ) : (
        <input className={"textInput"} style={styles.input} {...props}/>
      )}
    </div>
  );
}
