import React, {useState} from "react";
import {primary3, primary45, secondary, secondary2} from "../utils/colors";
import AttestationDialog from "./AttestationDialog";

export function MiniAttestation({ UUID }) {
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  const styles = {
    container: {
      // display: "inline-flex",
      flexDirection: 'column',
      borderRadius: 4,
      color: "#FFF",
      padding: "6px 8px",
      backgroundColor: secondary,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      textAlign: 'center'

    },
    title: {
      backgroundColor: secondary2,
      padding: 6,
      borderRadius: 4,
      fontSize: 14,
      fontFamily: "Open Sans",
      marginBottom: 4,
      fontWeight: 600,
    },
    UUID: {
      fontSize: 15,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  };
  return (
    <div style={styles.container} onClick={(event)=> {
      event.stopPropagation();
      setDetailsDialogOpen(true);
    }}>
      {detailsDialogOpen ? (
        <AttestationDialog
          onClose={(e) => {
            e.stopPropagation();
            setDetailsDialogOpen(false);
          }}
          UUID={UUID}
          whichAddress={'to'}
        />
      ) : null}
      <div style={styles.title}>Attestation</div>
      <div style={styles.UUID}>{UUID.substr(2,6)}</div>
    </div>
  );
}
