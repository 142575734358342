import React from "react";
import { cleanBorder, primary45 } from "../utils/colors";
import { LoadingForDialog } from "./LoadingForDialog";
import {useSelector} from 'react-redux';

export function CostEstimate({ estimate }) {
  const {usdPrice} = useSelector(
    (state) => state.tempData,
  );

  const styles = {
    container: {
      alignItems: "center",
      color: primary45,
      fontSize: 14,
      padding: 9,
      border: cleanBorder,
      borderRadius: 4,
      textAlign: "right",
      marginBottom: 10,
    },
    title: {
      fontWeight: 600,
      fontSize: 16,
    },
    currencyName: {
      fontSize: 13,
      fontWeight: 600,
    }
  };

  const usdCost = estimate * usdPrice;

  return (
    <div style={styles.container}>
      {estimate !== null ? (
        <>
          <div style={styles.title}>Estimated cost</div>
          <div style={styles.ethCost}>
            {estimate.toFixed(6)} <span style={styles.currencyName}>ETH</span>
          </div>
          <div style={styles.usdCost}>
            {usdCost.toFixed(2)} <span style={styles.currencyName}>USD</span>
          </div>
        </>
      ) : (
        <LoadingForDialog text={"Loading estimate..."} />
      )}
    </div>
  );
}
