import React from "react";
import { Dialog } from "./Dialog";
import { primary45 } from "../utils/colors";
import { BigButton } from "./BigButton";

export function InstallMetamaskDialog() {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      color: primary45
    },
    title: {
      marginBottom: 8,
    }
  };
  return (
    <Dialog
      isVisible={true}
      title={"Install metamask"}
      body={
        <div style={styles.container}>
          <div style={styles.title}>You must install Metamask to continue</div>
          <div>
            <BigButton
              style={styles.connectButton}
              onClick={()=>{
                document.location = "https://metamask.io/";
              }}
              title={"Install Metamask"}
            />
          </div>
        </div>
      }
    />
  );
}
