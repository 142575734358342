export const EASContractAddress = "0x763fc917204018b21A86B3A95432568c9500B492"; // Rinkeby
export const AORegistryContractAddress = "0x21af79Ef265F2f23F0998302215051ec7f7Bd7b1"; // Rinkeby
export const EAS712Address = ""; // Rinkeby
export const CHAINID = 5;

// export const EASContractAddress = "0x9eb1703fA2d0842D4EDf624BfeB6741ed5d91aeD"; // Ganache
// export const AORegistryContractAddress = "0x161B8e46938200370904F56484BD127C04939370"; // Ganache
// export const EAS712Address = "0xA1a547dd9Cfe1267caF397A585C9f43E2D1Bf4d3"; // Ganache
// export const CHAINID = 5777;
// export const rootURL = `http://localhost:41337`;
export const rootURL = `https://eas.dakh.net`;

