import React from "react";
import {primary2, primary45} from "../utils/colors";

export function SectionHeader({title, iconComponent, rightBody, borderColor=primary2}) {
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${borderColor}`,
      marginBottom: 16,
      paddingBottom: 8,
    },
    left: {
      color: primary45,
      display: 'flex',
      alignItems: 'center',
      padding: 8,
    },
    title: {
      marginLeft: 8,
      fontSize: 18,
      fontWeight: 600,
      color: primary45
    },
    icon: {
      width: 16,
      height: 16,
    }
  };

  const IconComponent = iconComponent;

  return <div style={styles.container}>
    <div style={styles.left}>
      <IconComponent color={primary45} style={styles.icon}/>
      <div style={styles.title}>{title}</div>
    </div>
    <div style={styles.right}>{rightBody}</div>

  </div>;
}
