import React, { useEffect, useState } from "react";
import "../App.css";
import { Header } from "../components/Header";
import useMetaMask from "../hooks/useMetamask";
import {
  cleanBorder,
  primary2,
  primary4,
  primary45,
  primary5,
} from "../utils/colors";
import makeBlockie from "ethereum-blockies-base64";
import { OverviewBlock } from "../components/OverviewBlock";
import { AttestationBlockList } from "../components/AttestationBlockList";
import { Dialog } from "../components/Dialog";
import { FaNetworkWired, FaPlus } from "react-icons/fa";
import { useIsSmallScreen } from "../hooks/useIsSmallScreen";
import { ButtonWithIcon } from "../components/ButtonWithIcon";
import usePrevious from "../hooks/usePrevious";
import axios from "axios";
import useAttestations from "../hooks/useAttestations";
import AttestationList from "./AttestationList";
import AddressGraph from "../components/AddressGraph";
import { rootURL } from "../utils/config";
import {Helmet} from "react-helmet";

function AddressPage({ match }) {
  const [account, metamaskInstalled, setAccount] = useMetaMask();
  const [score, setScore] = useState(null);
  const { address } = match.params;
  const [activeTab, setActiveTab] = useState("received");
  const [isAttestDialogVisible, setIsAttestDialogVisible] = useState(false);
  const [isGraphVisible, setIsGraphVisible] = useState(false);

  const isSmallScreen = useIsSmallScreen();
  const previousAddress = usePrevious(address);
  const [receivedAttestations, sentAttestations] = useAttestations(
    address,
    previousAddress
  );

  useEffect(() => {
    if (previousAddress === address) {
      return;
    }

    async function getScores() {
      setScore(null);
      const res = await axios.get(`${rootURL}/score/${address}`);
      setScore(res.data);
    }

    getScores();
  }, [address]);

  const styles = {
    container: {
      maxWidth: 1000,
      margin: "0 auto",
      backgroundColor: "#FFF",
      padding: 12,
      border: cleanBorder,
    },
    addressBlock: {
      display: isSmallScreen ? "block" : "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 12,
      paddingTop: 0,
      borderBottom: `1px solid ${primary2}`,
      marginBottom: 8,
    },
    addressLeft: {
      display: "flex",
      alignItems: "center",
    },
    addressRight: {
      display: "flex",
      alignItems: "center",
      justifyContent: isSmallScreen ? "flex-end" : null,
      marginTop: isSmallScreen ? 12 : 0,
    },
    address: {
      fontSize: 16,
      color: primary4,
      fontFamily: "Roboto Mono",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    addressIcon: {
      width: 22,
      height: 22,
      borderRadius: 4,
      marginRight: 8,
    },
    addressTitle: {
      fontWeight: 600,
      color: primary5,
      marginRight: 4,
      fontSize: 18,
    },
    overviewBlocks: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      // backgroundColor: primary45,
      // borderRadius: 6,
      // padding: "16px 0px",
    },
    graphButton: {
      marginRight: 8,
    },
  };

  return (
    <div>
      <Helmet>
        <title>EAS - Address - {address}</title>
      </Helmet>
      <Header
        metamaskInstalled={metamaskInstalled}
        account={account}
        setAccount={setAccount}
      />
      <Dialog
        onClose={() => setIsAttestDialogVisible(false)}
        title={`Attest to ${address}`}
        isVisible={isAttestDialogVisible}
        body={<AttestationBlockList attestToAddress={address} />}
      />
      <div style={styles.container}>
        <div style={styles.addressBlock}>
          <div style={styles.addressLeft}>
            <img src={makeBlockie(address)} style={styles.addressIcon} />
            <span style={styles.addressTitle}>Address</span>
            <span style={styles.address}>{address}</span>
          </div>
          <div style={styles.addressRight}>
            <ButtonWithIcon
              size={14}
              title={"Graph"}
              iconComponent={FaNetworkWired}
              style={styles.graphButton}
              onClick={() => setIsGraphVisible(!isGraphVisible)}
            />
            <ButtonWithIcon
              title={"Attest"}
              iconComponent={FaPlus}
              onClick={() => setIsAttestDialogVisible(true)}
            />
          </div>
        </div>

        {isGraphVisible ? (
          <AddressGraph address={address} account={account} />
        ) : null}

        <div style={styles.overviewBlocks}>
          <OverviewBlock
            value={score === null ? "..." : score.score}
            // color={"#5cd85c"}
            title={"Reputation"}
            smallTitle={"Score"}
          />
          <OverviewBlock
            value={
              score === null
                ? "..."
                : score?.rank?.rank === null
                ? "N/A"
                : `${score.rank.rank}/${score.rank.total}`
            }
            title={"Rank"}
            smallTitle={"Rank"}
          />
          <OverviewBlock
            value={score === null ? "..." : Math.round(score.goodness * 100)}
            // color={"#5cd85c"}
            title={"Goodness"}
            smallTitle={"Score"}
          />
          <OverviewBlock
            value={score === null ? "..." : Math.round(score.fairness * 100)}
            // color={"#5cd85c"}
            title={"Fairness"}
            smallTitle={"Score"}
          />

          <OverviewBlock
            value={
              sentAttestations === null || receivedAttestations === null
                ? "..."
                : `${receivedAttestations.length}/${sentAttestations.length}`
            }
            title={"Sent/Received"}
            smallTitle={"Sent/Received"}
            // onClick={() => setActiveTab("received")}
          />
          {/*<OverviewBlock*/}
          {/*  value={sentAttestations === null ? "..." : sentAttestations.length}*/}
          {/*  title={"Sent"}*/}
          {/*  smallTitle={"Sent"}*/}
          {/*  onClick={() => setActiveTab("sent")}*/}
          {/*/>*/}
        </div>

        <AttestationList
          receivedAttestations={receivedAttestations}
          account={account}
          sentAttestations={sentAttestations}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setIsAttestDialogVisible={setIsAttestDialogVisible}
        />
      </div>
    </div>
  );
}

export default AddressPage;
