import React from "react";
import {
  primary,
  primary3,
  primary35,
  primary45,
  primary46,
} from "../utils/colors";
import { FaSignature } from "react-icons/fa";
import { BigButton } from "./BigButton";

export function NoAttestations({ onAttest, whichAddress }) {
  const styles = {
    title: {
      fontSize: 20,
      color: primary3,
    },
    container: {
      textAlign: "center",
      marginTop: 12,
      paddingBottom: 12,
    },
    icon: {
      width: 40,
      height: 40,
    },
    create: {
      marginTop: 8,
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.iconHolder}>
        <FaSignature style={styles.icon} color={primary3} />
      </div>
      <div style={styles.title}>There are no attestations {whichAddress==='from'?'at':'to'} this address</div>
      {whichAddress === "from" ? (
        <BigButton
          title={"Attest"}
          style={styles.create}
          inverted={true}
          onClick={onAttest}
        />
      ) : null}
    </div>
  );
}
