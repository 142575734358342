import React, { useState } from "react";
import {
  cleanBorder,
  primary2,
  primary3,
  primary4,
  primary45,
  primary5,
} from "../utils/colors";
import { BigButton } from "./BigButton";
import { useWindowSize } from "../hooks/useWindowSize";

export function AttestationTypeBlock({
  title,
  iconComponent,
  description,
  attestDialog,
  attestToAddress,
}) {
  const windowSize = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);

  const styles = {
    container: {
      padding: 8,
      border: `1px solid ${primary45}`,
      borderRadius: 4,
      color: primary4,
      boxSizing: "border-box",
    },
    outer: {
      width: windowSize.width >= 680 ? 200 : "46%",
      padding: 8,
      boxSizing: "border-box",
    },
    title: {
      fontSize: 18,
      textAlign: "center",
      marginBottom: 8,
    },
    description: {
      backgroundColor: primary2,
      border: cleanBorder,
      padding: 4,
      borderRadius: 4,
      color: primary5,
      fontSize: 14,
      height: 78,
      overflow: 'hidden',
    },
    icon: {
      width: 58,
      height: 58,
    },
    iconHolder: {
      display: "inline-flex",
      padding: 8,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 4,
    },
    button: {
      marginTop: 8,
    },
    iconRow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 8,
    },
  };

  const AttestDialog = attestDialog;

  const IconComponent = iconComponent;

  if (title ==='Undefined') {
    return null;
  }

  return (
    <div style={styles.outer}>
      {attestDialog && isOpen ? (
        <AttestDialog
          attestToAddress={attestToAddress}
          isVisible={true}
          onClose={() => setIsOpen(false)}
        />
      ) : null}
      <div style={styles.container}>
        <div style={styles.iconRow}>
          <div style={styles.iconHolder}>
            <IconComponent style={styles.icon} color={primary3} />
          </div>
        </div>
        <div style={styles.title}>{title}</div>
        <div style={styles.description}>{description}</div>
        <div style={styles.buttonHolder}>
          <BigButton
            onClick={() => setIsOpen(true)}
            title={"Attest"}
            fullWidth={true}
            style={styles.button}
            inverted={true}
          />
        </div>
      </div>
    </div>
  );
}
