import React from "react";
import { cleanBorder, primary3, primary4 } from "../utils/colors";

export function IconOption({ iconComponent, title, onClick, selected }) {
  const styles = {
    container: {
      display: "inline-flex",
      flexDirection: "column",
      borderRadius: 8,
      padding: 20,
      border: cleanBorder,
      backgroundColor: selected ? primary3 : null,
      cursor: 'pointer',
    },
    outer: {
      padding: 12,
    },
    icon: {
      width: 60,
      height: 60,
    },
    title: {
      position: "relative",
      textAlign: "center",
      top: 12,
      color: selected ? "#FFF" : primary4,
    },
  };

  const IconComponent = iconComponent;

  return (
    <div style={styles.outer}>
      <div style={styles.container} onClick={onClick}>
        <IconComponent
          style={styles.icon}
          color={selected ? "#FFF" : primary3}
        />

        <div style={styles.title}>{title}</div>
      </div>
    </div>
  );
}
