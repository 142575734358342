import React from "react";
import { Attestation } from "./Attestation";
import { primary15, primary45 } from "../utils/colors";
import { NoAttestations } from "./NoAttestations";

export default function AttestationTable({
  attestations,
  whichAddress,
  setIsAttestDialogVisible,
  account,
  noHeader,
  showMiniFrom,
  showDetails,
}) {
  const styles = {
    table: {
      width: "100%",
    },
    tableHeader: {
      backgroundColor: primary15,
      color: primary45,
      borderRadius: 4,
      fontSize: 14,
      fontWeight: 600,
      borderBottom: "1px solid #000",
    },
    headerDate: {
      textAlign: "right",
      borderRadius: "0 0 4px 0",
    },
    headerAO: {
      borderRadius: "0 0 0 4px",
      textAlign: "left",
    },
    address: {
      textAlign: "center",
    },
  };

  if (attestations === null) {
    return null;
  }

  return (
    <>
      <table
        style={{ ...styles.table, marginBottom: 8 }}
        cellPadding={8}
        cellSpacing={0}
        border={0}
      >
        <tbody>
          {!noHeader ? (
            <>
              <tr style={styles.tableHeader}>
                <td style={styles.headerAO}>Type</td>
                <td style={styles.address}>
                  {whichAddress === "from" ? "From" : "To"}
                </td>
                <td style={styles.headerDate}>Age</td>
              </tr>
            </>
          ) : null}
        </tbody>
      </table>
      <table style={styles.table} cellPadding={8} cellSpacing={0} border={0}>
        <tbody>
          {attestations.map((attestation, index) => (
            <Attestation
              account={account}
              key={index}
              attestationData={attestation}
              whichAddress={whichAddress}
              showMiniFrom={showMiniFrom}
              showDetails={showDetails}
            />
          ))}
        </tbody>
      </table>
      {attestations.length === 0 ? (
        <NoAttestations
          onAttest={() => setIsAttestDialogVisible(true)}
          whichAddress={whichAddress}
        />
      ) : null}
    </>
  );
}
