import React from "react";
import { ethers } from "ethers";
import { attestationTypes } from "../attestationTypes";

export function AORegistryItem({ aoData }) {
  const styles = {
    address: {
      fontFamily: "Roboto Mono",
      textAlign: "center",
      fontSize: 14,
    },
    schema: {
      textAlign: "right",
      fontSize: 14,
    },
    title: {
      fontSize: 13,
      marginLeft: 4,
    },
    id: {
      display: 'flex',
      alignItems: 'center'
    }
  };
  const schemaStr = ethers.utils.toUtf8String(aoData.schema);

  const aoNum = aoData.id.toNumber();
  const attestationData = attestationTypes[aoNum];

  return (
    <>
      <tr>
        <td style={styles.id}>
          {aoNum}{" "}
          {attestationData ? (
            <span style={styles.title}>[{attestationData.title}]</span>
          ) : null}
        </td>
        <td style={styles.address}>{aoData.verifier}</td>
        <td style={styles.schema}>{schemaStr !== "\0" ? schemaStr : "N/A"}</td>
      </tr>
    </>
  );
}
