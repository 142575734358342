import { ethers } from "ethers";
import React from "react";
import { Pill } from "./Pill";
import {primary2} from "../utils/colors";

export function AOPill({ ao }) {
  const styles = {
    aoPill: {
      display: "inline-flex",
      alignItems: "center",
      padding: 2,
    },
    num: {
      fontSize: 18,
      marginLeft: 4,
      fontWeight: 600
    },
    subtitle: {
      fontSize: 12,
      fontWeight: 600,
      color: primary2,
    }
  };

  const aoString = ethers.BigNumber.from(ao).toString();
  return (
    <Pill
      title={
        <div>
          <div style={styles.aoPill}>
            <div style={styles.subtitle}>AO |</div>
            <div style={styles.num}>{aoString}</div>
          </div>
        </div>
      }
    />
  );
}
