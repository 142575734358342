import React, { useEffect, useState } from "react";
import "../App.css";
import { Header } from "../components/Header";
import useMetaMask from "../hooks/useMetamask";
import { cleanBorder, primary3, primary45 } from "../utils/colors";
import AddressGraph from "../components/AddressGraph";
import axios from "axios";
import {rootURL} from "../utils/config";
import {SectionHeader} from "../components/SectionHeader";
import { FaList } from "react-icons/fa";
import AttestationTable from "../components/AttestationTable";
import {Helmet} from "react-helmet";

function App() {
  const [account, metamaskInstalled, setAccount] = useMetaMask();
  const [recentAttestations, setRecentAttestations] = useState(null);
  useEffect(() => {
    async function go() {
      const res = await axios.get(`${rootURL}/getRecentAttestations`);
      setRecentAttestations(res.data.attestations.reverse());
    }

    go();
  }, []);

  const styles = {
    container: {
      maxWidth: 1000,
      margin: "0 auto",
      backgroundColor: "#FFF",
      border: cleanBorder,
      padding: 12,
      color: primary45,
    },
  };

  return (
    <div>
      <Helmet>
        <title>EAS - Ethereum Attestation System</title>
      </Helmet>
      <Header
        metamaskInstalled={metamaskInstalled}
        account={account}
        setAccount={setAccount}
      />
      <div style={styles.container}>
        <AddressGraph
          address={"all"}
          title={"Full graph"}
          dragEnabled={true}
          account={account}
        />

        <SectionHeader title={'Recent attestations'} iconComponent={FaList}/>
        <div>
          <AttestationTable attestations={recentAttestations} />
        </div>
      </div>
    </div>
  );
}

export default App;
