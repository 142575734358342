import React, {useEffect} from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import App from "./App";
import AddressPage from "./AddressPage";
import {hot} from 'react-hot-loader'
import Registry from "./Registry";
import {useDispatch} from 'react-redux';
import {getUSDPrice} from "../utils/Utils";
import {setUSDPrice} from "../actions/tempData";

function Routes() {
  const dispatch = useDispatch();

  useEffect(()=> {
    async function load() {
      const usdPrice = await getUSDPrice();
      dispatch(setUSDPrice(usdPrice));
    }

    load();
  })

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={App} />
        <Route exact path="/address/:address" component={AddressPage} />
        <Route exact path="/registry" component={Registry} />
      </Switch>
    </Router>
  );
}

export default hot(module)(Routes);
