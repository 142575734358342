import React from "react";
import useHover from "../hooks/useHover";

export function MenuItem({ title, onClick, selected }) {
  const [hoverRef, isHovered] = useHover();

  const styles = {
    container: {
      fontWeight: selected ? "600" : null,
      display: "inline-flex",
      padding: 8,
      textDecoration: isHovered ? "underline" : null,
      cursor: "pointer",
    },
  };
  return (
    <div style={styles.container} ref={hoverRef} onClick={onClick}>
      {title}
    </div>
  );
}
