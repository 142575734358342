import React, { useEffect, useState } from "react";
import Graph from "react-graph-vis";
import axios from "axios";
import { getNodeColor } from "../utils/Utils";
import {
  cleanBorder,
  goodColor,
  lightErrorColor,
  primary2,
  primary3,
  primary45,
} from "../utils/colors";
import { SectionHeader } from "./SectionHeader";
import { FaNetworkWired } from "react-icons/fa";
import { rootURL } from "../utils/config";
import {LoadingForDialog} from "./LoadingForDialog";

function getEdgeColor(weight) {
  if (weight > 0) {
    return goodColor;
  } else if (weight < 0) {
    return lightErrorColor;
  } else {
    return primary3;
  }
}

export default function AddressGraph({
  address,
  account,
  title = "Local graph",
  dragEnabled = false,
}) {
  const styles = {
    container: {
      padding: 8,
      height: 500,
      backgroundColor: primary2,
      border: cleanBorder,
      borderRadius: 4,
      marginBottom: 8,
      boxSizing: "border-box",
      overflow: "hidden",
      color: primary45
    },
  };
  const [graph, setGraph] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (!account) {
      return;
    }

    function formatAddress(address) {
      return address.substr(2, 8);
    }

    async function loadGraph() {
      setGraph(null);
      const res = await axios.get(`${rootURL}/graph/${account}/${address}`);
      const graphJSON = res.data;

      if (graphJSON.error) {
        setError(true);
        return;
      }

      const tmpGraph = {
        nodes: graphJSON.nodes.map((node) => ({
          id: node.id,
          color: getNodeColor(node, address, account),
          label: `${formatAddress(node.id)}: ${node.value}`,
          // title: `<div class="scoreBox"><b>Score:</b> ${node.value}</div>`,
          // value: node.value * 10000,
          font: {
            color: "#FFF",
          },
          margin: 8,
          scaling: {
            label: {
              // enabled: false,
            },
          },
        })),
        edges: graphJSON.links.map((edge) => ({
          from: edge.source,
          to: edge.target,
          color: getEdgeColor(edge.weight),
          // title: `<div class="scoreBox">${edge.weight}</div>`,
          label: edge.weight.toFixed(2),
          font: {
            color: primary45,
          },
          // value: edge.weight,
        })),
      };
      setGraph(tmpGraph);
    }

    loadGraph();
  }, [address, account]);

  const options = {
    interaction: {
      zoomView: dragEnabled,
      dragView: dragEnabled,
      hover: true,
    },
    layout: {
      improvedLayout: true,
    },
    edges: {
      color: primary3,
    },
    nodes: {
      shape: "box",
      color: primary45,
      font: {
        color: "#FFF",
      },
    },
    height: "500px",
  };

  const events = {
    select: function (event) {
      console.log("ev", event);
      const { nodes, edges } = event;
      if (nodes.length) {
        document.location = `/#/address/${nodes[0]}`;
      }
    },
  };

  return (
    <div style={styles.container}>
      <SectionHeader
        title={title}
        iconComponent={FaNetworkWired}
        borderColor={primary3}
      />

      {error ? <div style={styles.text}>No attestations yet!</div> : null}
      {!error && !graph?<LoadingForDialog position={'flex-start'} text={'Loading...'}/>:null}
      {graph ? (
        <Graph
          graph={graph}
          options={options}
          events={events}
          getNetwork={(network) => {}}
        />
      ) : null}
    </div>
  );
}
