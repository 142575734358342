import React, { useState } from "react";
import { Dialog } from "../Dialog";
import produce from "immer";
import { ConfirmOrCancel } from "../ConfirmOrCancel";
import { Contract, ethers } from "ethers";
import AORegistryAbi from "../../abis/AORegistryAbi.json";
import { LoadingForDialog } from "../LoadingForDialog";
import {
  AORegistryContractAddress,
  EASContractAddress,
} from "../../utils/config";
import {
  getDefaultExpiration,
  getReadOnlyProvider,
  getSigningProvider,
} from "../../utils/Utils";
import "rc-slider/assets/index.css";
import { primary4 } from "../../utils/colors";
import { TextInput } from "../TextInput";
import { AutoInput } from "../AutoInput";
import EASabi from "../../abis/EASabi.json";

// Custom attestation
export default function AO_Custom({ isVisible, onClose, attestToAddress }) {
  const [loading, setLoading] = useState(false);
  const [aoNum, setAONum] = useState(null);
  const [aoLoaded, setAOLoaded] = useState(null);
  const [inputValues, setInputValues] = useState([]);

  const styles = {
    actions: {
      display: "flex",
      justifyContent: "flex-end",
    },
    cancel: {
      marginRight: 8,
    },
    optionsHolder: {
      display: "flex",
      justifyContent: "center",
    },
    sliderContainer: {
      minWidth: 180,
      marginBottom: 12,
      marginTop: 12,
    },
    value: {
      textAlign: "center",
      color: primary4,
      fontSize: 18,
      fontFamily: "Roboto Mono",
      marginTop: 8,
    },
  };

  async function loadAO() {
    const provider = getReadOnlyProvider();

    const contract = new Contract(
      AORegistryContractAddress,
      AORegistryAbi,
      provider
    );

    setLoading(true);
    const [id, schema, verifier] = await contract.getAO(aoNum);
    setLoading(false);

    const schemaStr = ethers.utils.toUtf8String(schema);

    if (schemaStr !== "\0" && schemaStr.length > 2) {
      const frag = ethers.utils.FunctionFragment.from(`testFunc(${schemaStr})`);

      const items = frag.inputs.map((paramType, i) => {
        const { name, type } = paramType;

        return { name, type, value: type === "bool" ? false : "" };
      });

      setInputValues(items);

      setAOLoaded({ id, schema: schemaStr, verifier });
    }
  }

  return (
    <Dialog
      isVisible={isVisible}
      onClose={onClose}
      title={"Create custom attestation"}
      body={
        <div style={styles.container}>
          <div style={styles.optionsHolder}>
            <div style={styles.sliderContainer}>
              {!aoLoaded ? (
                <div style={styles.aoNUm}>
                  <TextInput
                    value={aoNum}
                    onChange={(event) => setAONum(event.target.value)}
                  />
                  <div style={styles.value}>Enter an AO#</div>
                </div>
              ) : (
                <div>
                  {inputValues.map((input, i) => (
                    <AutoInput
                      key={i}
                      input={input}
                      onChange={(value) => {
                        const tmpInputValues = produce(inputValues, (draft) => {
                          draft[i].value = value;
                        });

                        setInputValues(tmpInputValues);
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>

          {!loading ? (
            <ConfirmOrCancel
              onCancel={onClose}
              onConfirm={async () => {
                if (!aoLoaded) {
                  loadAO();
                  return;
                }

                const signer = getSigningProvider();

                const contract = new Contract(
                  EASContractAddress,
                  EASabi,
                  signer
                );

                const types = inputValues.map((iv) => iv.type);
                const values = inputValues.map((iv) => iv.value);
                const coder = ethers.utils.defaultAbiCoder;
                const encoded = coder.encode(types, values);

                const params = [
                  attestToAddress,
                  aoNum,
                  getDefaultExpiration(),
                  ethers.constants.HashZero,
                  encoded,
                ];

                const tx = await contract.attest.apply(null, params);

                // const tx = await contract.attest(
                //   attestToAddress,
                //   3,
                //   getDefaultExpiration(),
                //   ethers.constants.HashZero,
                //   encoded
                // );

                setLoading(true);

                setTimeout(async () => {
                  await tx.wait();

                  setLoading(false);
                  onClose();
                }, 50);
              }}
            />
          ) : (
            <LoadingForDialog text={!aoLoaded ? "Loading AO..." : undefined} />
          )}
        </div>
      }
    />
  );
}
