import React, {useEffect, useState} from "react";
import { Dialog } from "../Dialog";
import { attestationTypes } from "../../attestationTypes";
import Well from "../Well";
import { ConfirmOrCancel } from "../ConfirmOrCancel";
import { Contract, ethers } from "ethers";
import EASabi from "../../abis/EASabi.json";
import { LoadingForDialog } from "../LoadingForDialog";
import { EASContractAddress } from "../../utils/config";
import { IconOption } from "../IconOption";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { getDefaultExpiration, getSigningProvider } from "../../utils/Utils";
import {CostEstimate} from "../CostEstimate";

// Like attestation
export default function AO_2({ isVisible, onClose, attestToAddress }) {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const attestationType = attestationTypes[2];
  const [estimatedCost, setEstimatedCost] = useState(null);

  const signer = getSigningProvider();

  const contract = new Contract(
    EASContractAddress,
    EASabi,
    signer
  );

  useEffect(() => {
    async function estimateCost() {
      const estimate = await contract.estimateGas.attest(
        attestToAddress,
        2,
        getDefaultExpiration(),
        ethers.constants.HashZero,
        ethers.constants.One,
      );

      const formattedEstimate = Number(
        ethers.utils.formatUnits(estimate, "gwei")
      );

      setEstimatedCost(formattedEstimate);
    }

    estimateCost();
  }, []);

  const styles = {
    actions: {
      display: "flex",
      justifyContent: "flex-end",
    },
    cancel: {
      marginRight: 8,
    },
    optionsHolder: {
      display: "flex",
      justifyContent: "center",
    },
  };

  return (
    <Dialog
      isVisible={isVisible}
      onClose={onClose}
      title={"Create like attestation"}
      body={
        <div style={styles.container}>
          <Well>{attestationType.description}</Well>
          <CostEstimate estimate={estimatedCost}/>

          <div style={styles.optionsHolder}>
            <IconOption
              title={"Like"}
              iconComponent={FaThumbsUp}
              selected={selected === "Like"}
              onClick={() => setSelected("Like")}
            />
            <IconOption
              title={"Dislike"}
              iconComponent={FaThumbsDown}
              selected={selected === "Dislike"}
              onClick={() => setSelected("Dislike")}
            />
          </div>

          {!loading ? (
            <ConfirmOrCancel
              onCancel={onClose}
              onConfirm={async () => {
                if (!selected) return;

                const coder = ethers.utils.defaultAbiCoder;
                const encoded = coder.encode(["bool"], [selected === "Like"]);

                const tx = await contract.attest(
                  attestToAddress,
                  2,
                  getDefaultExpiration(),
                  ethers.constants.HashZero,
                  encoded
                );

                setLoading(true);

                setTimeout(async () => {
                  await tx.wait();

                  setLoading(false);
                  onClose();
                }, 50);
              }}
            />
          ) : (
            <LoadingForDialog />
          )}
        </div>
      }
    />
  );
}
