import React, { useEffect, useState } from "react";
import { primary2, primary45 } from "../utils/colors";
import { BigButton } from "./BigButton";
import { FaCircle, FaCubes } from "react-icons/fa";
import SearchInput from "./SearchInput";
import { Pill } from "./Pill";
import { useIsSmallScreen } from "../hooks/useIsSmallScreen";
import { getReadOnlyProvider, navigateToAddress } from "../utils/Utils";
import { InstallMetamaskDialog } from "./InstallMetamaskDialog";
import { MenuItem } from "./MenuItem";

export function Header({ account, metamaskInstalled, setAccount }) {
  const isSmallScreen = useIsSmallScreen();
  const [blockNum, setBlockNum] = useState(0);

  useEffect(() => {
    let provider = null;

    async function getBlock() {
      try {
        const provider = getReadOnlyProvider();

        provider.on("block", (blockNumber) => {
          setBlockNum(blockNumber);
        });
      } catch (e) {
        console.log("getBlock error", e);
      }
    }

    getBlock();

    return () => {
      if (provider !== null) {
        provider.off("block");
      }
    };
  }, []);

  const styles = {
    container: {
      padding: 12,
      color: "#FFF",
      backgroundColor: primary45,
    },
    left: {},
    logoBlock: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    icon: {
      width: 20,
      height: 20,
      marginRight: 2,
    },
    address: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      fontSize: 13,
      textAlign: "right",
    },
    connected: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      marginBottom: 8,
    },
    connectedDot: {
      position: "relative",
      top: 1,
      marginLeft: 3,
      width: 8,
      height: 8,
    },
    searchBlock: {
      marginTop: 12,
      marginBottom: isSmallScreen ? 12 : 0,
    },
    middle: {
      display: isSmallScreen ? "block" : "flex",
      justifyContent: "space-between",
      alignItems: "center",
      maxWidth: 1000,
      margin: "0 auto",
    },
    menu: {
      display: 'flex',
      alignItems: "center",
      maxWidth: 1000,
      margin: "0 auto",
    },
    blockContainer: {
      display: "inline-flex",
      alignItems: "center",
    },
    cubes: {
      marginRight: 4,
    },
    connectButton: {
      marginTop: 8,
      textAlign: "center",
    },
    connectButtonContainer: {
      display: "flex",
      justifyContent: "center",
    },
    accountAddress: {
      cursor: "pointer",
    },
  };

  const connectWallet = async () => {
    if (!metamaskInstalled) {
      document.location = "https://metamask.io/";
      return;
    }

    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const account = accounts[0];

    setAccount(account);
  };

  return (
    <div style={styles.container}>
      {!metamaskInstalled ? <InstallMetamaskDialog /> : null}
      <div style={styles.middle}>
        <div style={styles.left}>
          <div
            style={styles.logoBlock}
            onClick={() => (document.location = "/#/")}
          >
            <img style={styles.icon} src={require("../ethereum.png")} alt="" />
            Ethereum Attestation System
          </div>
          <div style={styles.searchBlock}>
            <SearchInput />
          </div>
        </div>
        <div>
          {!account ? (
            <div style={styles.connectButtonContainer}>
              <BigButton
                style={styles.connectButton}
                onClick={connectWallet}
                title={
                  !metamaskInstalled ? "Install Metamask" : "Connect wallet"
                }
                customFillColor={"#FFF"}
                customWhiteColor={primary45}
              />
            </div>
          ) : (
            <div style={styles.address}>
              <div style={styles.connected}>
                <Pill
                  title={
                    <div style={styles.blockContainer}>
                      <FaCubes style={styles.cubes} color={primary2} />{" "}
                      {blockNum}
                    </div>
                  }
                />
                <FaCircle color={"#00FF00"} style={styles.connectedDot} />
              </div>
              <div
                style={styles.accountAddress}
                onClick={() => navigateToAddress(account)}
              >
                {account}
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={styles.menu}>
        <MenuItem title={'Home'} selected={true} onClick={()=>document.location='/#/'}/>
        <MenuItem title={'AO Registry'} onClick={()=>document.location='/#/registry'}/>
      </div>
    </div>
  );
}
