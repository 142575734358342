import { Contract, ethers, providers } from "ethers";
import {
  AORegistryContractAddress,
  CHAINID,
  EAS712Address,
  EASContractAddress,
} from "./config";
import EASabi from "../abis/EASabi.json";
import AORegistryAbi from "../abis/AORegistryAbi.json";
import axios from "axios";
import { recoverTypedSignature_v4 } from "eth-sig-util";
import { goodColor, primary5, primary6, secondary, secondary2 } from "./colors";

const { Proxy } = require("eas-sdk");

const proxy = new Proxy({
  address: EAS712Address,
  version: "0.1",
  chainId: CHAINID,
});

export async function getAttestation(UUID) {
  const provider = getReadOnlyProvider();
  const contract = new Contract(EASContractAddress, EASabi, provider);

  const [
    uuid,
    ao,
    to,
    from,
    time,
    expirationTime,
    revocationTime,
    refUUID,
    data,
  ] = await contract.getAttestation(UUID);

  return {
    ao,
    to,
    from,
    time,
    expirationTime,
    revocationTime,
    data,
    refUUID,
    UUID,
  };
}

export async function getAttestationsForUUIDS(UUIDS, contract) {
  let tmpAttestations = [];

  for (let UUID of UUIDS) {
    const attestation = await getAttestation(UUID);

    tmpAttestations.push(attestation);
  }

  return tmpAttestations;
}

async function getAttestations(address, contract, receiveFunc) {
  let tmpAttestations = [];

  for (let aoNum = 0; aoNum < 10; aoNum++) {
    const UUIDS = await receiveFunc(address, aoNum, 0, 100, true);

    for (let UUID of UUIDS) {
      const attestation = await getAttestation(UUID);

      tmpAttestations.push(attestation);
    }
  }

  return tmpAttestations;
}

function sortAttestations(a, b) {
  return a.time > b.time ? -1 : 1;
}

export async function getReceivedAttestations(address) {
  const provider = getReadOnlyProvider();
  const contract = new Contract(EASContractAddress, EASabi, provider);

  const attestations = await getAttestations(
    address,
    contract,
    contract.getReceivedAttestationUUIDs
  );

  return attestations.sort(sortAttestations);
}

export async function getSentAttestations(address) {
  const provider = getReadOnlyProvider();
  const contract = new Contract(EASContractAddress, EASabi, provider);

  const attestations = await getAttestations(
    address,
    contract,
    contract.getSentAttestationUUIDs
  );

  return attestations.sort(sortAttestations);
}

export function navigateToAddress(address) {
  document.location = `/#/address/${address}`;
}

export function getSigningProvider() {
  const provider = new providers.Web3Provider(window.web3.currentProvider);

  return provider.getSigner();
}

export function getReadOnlyProvider() {
  return new providers.Web3Provider(window.web3.currentProvider);
  //
  // return new providers.InfuraProvider(
  //   "rinkeby",
  //   "7beca79f4be84480b5557a579b1016dc"
  // );
}

export function getInfuraProvider() {
  return new providers.InfuraProvider(
    "rinkeby",
    "7beca79f4be84480b5557a579b1016dc"
  );
}

export function getDefaultExpiration() {
  return ethers.constants.MaxUint256;
}

export async function getAOs() {
  const provider = getReadOnlyProvider();
  const contract = new Contract(
    AORegistryContractAddress,
    AORegistryAbi,
    provider
  );

  let AOs = [];

  for (let aoNum = 1; aoNum < 100; aoNum++) {
    console.log("checking " + aoNum);
    const [id, schema, verifier] = await contract.getAO(aoNum);

    if (id.isZero()) {
      break;
    }

    AOs.push({ id, schema, verifier });
  }

  return AOs;
}

export async function getUSDPrice() {
  try {
    const result = await axios.get(
      "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD"
    );
    return result.data.USD;
  } catch (e) {
    return null;
  }
}

export async function verifyProxyAttestation(req, signingAddress) {
  return await proxy.verifyAttestationTypedDataRequest(
    signingAddress,
    req,
    (msg, signature) => {
      return recoverTypedSignature_v4({
        data: msg,
        sig: ethers.utils.joinSignature(signature),
      });
    }
  );
}

export async function makeProxyAttestation(params) {
  const signingAddress = window.web3.eth.accounts[0];
  const sign = async (message) => {
    return await window.ethereum.request({
      method: "eth_signTypedData_v4",
      params: [signingAddress, message],
      from: signingAddress,
    });
  };

  return await proxy.getAttestationTypedDataRequest(params, async (data) => {
    const { v, r, s } = ethers.utils.splitSignature(
      await sign(JSON.stringify(data))
    );
    return { v, r, s };
  });
}

export function getNodeColor(node, address, account) {
  const adminList = ["0x1e9ca69947df94e4fb0c0a681c89b1a1297fbd56", "0x2000000000000000000000000000000000000000", "0x1000000000000000000000000000000000000000"];
  return node.id === address.toLowerCase()
    ? goodColor
    : adminList.includes(node.id)
    ? secondary2
    : node.id.toLowerCase() === account?.toLowerCase()
    ? primary5
    : null;
}
