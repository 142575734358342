import React from "react";
import { SectionHeader } from "../components/SectionHeader";
import { FaList } from "react-icons/fa";
import { Tab } from "../components/Tab";
import AttestationTable from "../components/AttestationTable";
import { primary4 } from "../utils/colors";

export default function AttestationList({
  account,
  receivedAttestations,
  sentAttestations,
  activeTab,
  setActiveTab,
  setIsAttestDialogVisible,
}) {
  const styles = {
    loading: {
      color: primary4,
      padding: 8,
    },
  };
  return (
    <div>
      <SectionHeader iconComponent={FaList} title={"Attestations"} />

      {receivedAttestations === null ? (
        <div style={styles.loading}>Loading...</div>
      ) : (
        <div>
          <div style={styles.tabs}>
            <Tab
              title={"Received"}
              selected={activeTab === "received"}
              onClick={() => setActiveTab("received")}
            />
            <Tab
              title={"Sent"}
              selected={activeTab === "sent"}
              onClick={() => setActiveTab("sent")}
            />
          </div>
          {activeTab === "received" ? (
            <AttestationTable
              account={account}
              attestations={receivedAttestations}
              whichAddress={"from"}
              setIsAttestDialogVisible={setIsAttestDialogVisible}
            />
          ) : null}
          {activeTab === "sent" ? (
            <AttestationTable
              account={account}
              attestations={sentAttestations}
              whichAddress={"to"}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}
