import React, { useState } from "react";
import { primary3, primary45 } from "../utils/colors";
import { FaSearch } from "react-icons/fa";
import { ethers } from "ethers";
import { useIsSmallScreen } from "../hooks/useIsSmallScreen";
import { getReadOnlyProvider, navigateToAddress } from "../utils/Utils";
import Well from "./Well";

export default function SearchInput() {
  const [searchTxt, setSearchTxt] = useState("");
  const [error, setError] = useState(null);
  const isSmallScreen = useIsSmallScreen();

  const styles = {
    container: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: !error ? 12 : 0,
    },
    search: {
      fontSize: 14,
      color: primary45,
      borderRadius: "4px 0 0 4px",
      width: isSmallScreen ? "100%" : 280,
      border: 0,
      outline: 0,
      padding: 8,
    },
    icon: {
      width: 20,
      height: 20,
    },
    button: {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 8,
      backgroundColor: primary3,
      borderRadius: "0 4px 4px 0",
    },
    errorContainer: {
      marginTop: 4,
    },
  };

  async function goTo(searchTxt) {
    setError(null);

    if (ethers.utils.isAddress(searchTxt)) {
      navigateToAddress(searchTxt);
      setSearchTxt("");
    } else {
      const provider = getReadOnlyProvider();
      const address = await provider.resolveName(searchTxt);
      if (ethers.utils.isAddress(address)) {
        navigateToAddress(address);
        setSearchTxt("");
      } else {
        setError("Invalid address!");
      }
    }
  }

  return (
    <div>
      <div style={styles.container}>
        <input
          className={"textInput"}
          type={"text"}
          style={styles.search}
          value={searchTxt}
          onKeyPress={async (event) => {
            if (event.key === "Enter") {
              goTo(searchTxt);
            }
          }}
          onChange={(event) => setSearchTxt(event.target.value)}
          placeholder={"Search by identity or user address"}
        />
        <div
          style={styles.button}
          onClick={() => {
            goTo(searchTxt);
          }}
        >
          <FaSearch style={styles.icon} color={"#FFF"} />
        </div>
      </div>
      {error ? (
        <div style={styles.errorContainer}>
          <Well isError={true}>{error}</Well>
        </div>
      ) : null}
    </div>
  );
}
