import React from "react";
import { primary3 } from "../utils/colors";
import { EASContractAddress } from "../utils/config";

export function Footer() {
  const styles = {
    container: {
      paddingTop: 8,
      color: primary3,
      width: 1024,
      margin: "0 auto",
      fontSize: 13,
    },
  };
  return (
    <div style={styles.container}>
      EAS Contract:
      <a
        target={"_blank"}
        href={`https://rinkeby.etherscan.io/address/${EASContractAddress}`}
      >
        {" "}
        {EASContractAddress}
      </a>
    </div>
  );
}
