import { tempData } from "../actions/actionTypes";

const initialState = {
  usdPrice: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case tempData.SET_USD_PRICE: {
      return {
        ...state,
        usdPrice: action.usdPrice,
      };
    }
  }

  return state;
}
