import React, {useEffect, useState} from "react";
import { Dialog } from "../Dialog";
import { attestationTypes } from "../../attestationTypes";
import Well from "../Well";
import { ConfirmOrCancel } from "../ConfirmOrCancel";
import { Contract, ethers } from "ethers";
import EASabi from "../../abis/EASabi.json";
import { LoadingForDialog } from "../LoadingForDialog";
import { EASContractAddress } from "../../utils/config";
import { getDefaultExpiration, getSigningProvider } from "../../utils/Utils";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { primary4, primary45 } from "../../utils/colors";
import {CostEstimate} from "../CostEstimate";

// Rating attestation
export default function AO_2({ isVisible, onClose, attestToAddress }) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(10);
  const attestationType = attestationTypes[3];
  const [estimatedCost, setEstimatedCost] = useState(null);

  const signer = getSigningProvider();

  const contract = new Contract(
    EASContractAddress,
    EASabi,
    signer
  );

  useEffect(() => {
    async function estimateCost() {
      const estimate = await contract.estimateGas.attest(
        attestToAddress,
        3,
        getDefaultExpiration(),
        ethers.constants.HashZero,
        ethers.constants.HashZero,
      );

      const formattedEstimate = Number(
        ethers.utils.formatUnits(estimate, "gwei")
      );

      setEstimatedCost(formattedEstimate);
    }

    estimateCost();
  }, []);

  const styles = {
    actions: {
      display: "flex",
      justifyContent: "flex-end",
    },
    cancel: {
      marginRight: 8,
    },
    optionsHolder: {
      display: "flex",
      justifyContent: "center",
    },
    sliderContainer: {
      minWidth: 180,
      marginBottom: 12,
      marginTop: 12,
    },
    value: {
      textAlign: "center",
      color: primary4,
      fontSize: 18,
      fontFamily: "Roboto Mono",
      marginTop: 8,
    },
  };

  return (
    <Dialog
      isVisible={isVisible}
      onClose={onClose}
      title={"Create rating attestation"}
      body={
        <div style={styles.container}>
          <Well>{attestationType.description}</Well>
          <CostEstimate estimate={estimatedCost}/>

          <div style={styles.optionsHolder}>
            <div style={styles.sliderContainer}>
              <Slider
                handleStyle={{ borderColor: primary45 }}
                min={1}
                max={10}
                step={1}
                value={value}
                onChange={(val) => setValue(val)}
              />
              <div style={styles.value}>Attest a rating of {value}</div>
            </div>
          </div>

          {!loading ? (
            <ConfirmOrCancel
              onCancel={onClose}
              onConfirm={async () => {
                const coder = ethers.utils.defaultAbiCoder;
                const encoded = coder.encode(["uint8"], [value]);

                const tx = await contract.attest(
                  attestToAddress,
                  3,
                  getDefaultExpiration(),
                  ethers.constants.HashZero,
                  encoded
                );

                setLoading(true);

                setTimeout(async () => {
                  await tx.wait();

                  setLoading(false);
                  onClose();
                }, 50);
              }}
            />
          ) : (
            <LoadingForDialog />
          )}
        </div>
      }
    />
  );
}
