import React from "react";
import { errorColor, goodColor } from "../utils/colors";

export function RatingIndicator({ value }) {
  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: value >= 5 ? goodColor : errorColor,
      color: "#FFF",
      padding: 4,
      borderRadius: 4,
      fontSize: 16,
      width: 20,
      textAlign: "center",
    },
    value: {

    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.value}>{value}</div>
    </div>
  );
}
