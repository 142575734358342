import { FaBan, FaStar, FaThumbsUp } from "react-icons/fa";
import AO_1 from "./components/attestationDialogs/AO_1";
import AO_2 from "./components/attestationDialogs/AO_2";
import AO_3 from "./components/attestationDialogs/AO_3";

export const attestationTypes = [
  {
    title: "Undefined"
  },
  {
    title: "Null",
    description:
      "This is an attestation with no data. You are simply attesting to an address existing.",
    iconComponent: FaBan,
    attestDialog: AO_1,
    schema: '',
  },
  {
    title: "Like",
    description: "This attestation is used to either like/dislike an entity.",
    iconComponent: FaThumbsUp,
    attestDialog: AO_2,
    schema: 'bool value',
  },
  {
    title: "Rating",
    description: "This attestation is used to rate an entity between 1-10.",
    iconComponent: FaStar,
    attestDialog: AO_3,
    schema: 'uint8 value',
  },{
    title: "Book Recommendation",
    description: "This attestation is used to rate an entity between 1-10.",
    iconComponent: FaStar,
    // attestDialog: AO_3,
    schema: 'uint32 ISBN,bool like',
    hidden: true,
  },
];
