import React, { useEffect, useState } from "react";
import "../App.css";
import { Header } from "../components/Header";
import useMetaMask from "../hooks/useMetamask";
import { cleanBorder, primary45 } from "../utils/colors";
import { getAOs } from "../utils/Utils";
import { SectionHeader } from "../components/SectionHeader";
import { FaList, FaPlus } from "react-icons/fa";
import AORegistryTable from "../components/AORegistryTable";
import { ButtonWithIcon } from "../components/ButtonWithIcon";
import RegisterAODialog from "../components/RegisterAODialog";
import {Helmet} from "react-helmet";

function Registry() {
  const [account, metamaskInstalled, setAccount] = useMetaMask();
  const [aoList, setAOList] = useState(null);
  const [registerDialogVisible, setRegisterDialogVisible] = useState(false);

  useEffect(() => {
    async function go() {
      const allAOs = await getAOs();
      setAOList(allAOs);
      console.log(allAOs);
    }

    go();
  }, []);

  const styles = {
    container: {
      maxWidth: 1000,
      margin: "0 auto",
      backgroundColor: "#FFF",
      border: cleanBorder,
      padding: 12,
      color: primary45,
    },
  };
  return (
    <div>
      <Helmet>
        <title>EAS - AO Registry</title>
      </Helmet>
      {registerDialogVisible ? (
        <RegisterAODialog onClose={() => setRegisterDialogVisible(false)} />
      ) : null}
      <Header
        metamaskInstalled={metamaskInstalled}
        account={account}
        setAccount={setAccount}
      />
      <div style={styles.container}>
        <SectionHeader
          title={"AO Registry"}
          iconComponent={FaList}
          rightBody={
            <div>
              <ButtonWithIcon
                title={"Register AO"}
                iconComponent={FaPlus}
                onClick={() => setRegisterDialogVisible(true)}
              />
            </div>
          }
        />
        {aoList ? (
          <div>
            <AORegistryTable aos={aoList} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Registry;
