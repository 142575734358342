import React, { useEffect, useState } from "react";
import { Dialog } from "./Dialog";
import AttestationTable from "./AttestationTable";
import { Contract } from "ethers";
import { EASContractAddress } from "../utils/config";
import EASabi from "../abis/EASabi.json";
import {
  getAttestation,
  getAttestationsForUUIDS,
  getReadOnlyProvider,
} from "../utils/Utils";
import { SectionHeader } from "./SectionHeader";
import { FaList } from "react-icons/fa";
import { LoadingForDialog } from "./LoadingForDialog";

export default function AttestationDialog({
  UUID,
  attestationData,
  onClose,
  whichAddress,
}) {
  const [relatedAttestations, setRelatedAttestations] = useState(null);
  const [loadedAttestationData, setLoadedAttesttationData] = useState(
    attestationData
  );
  const styles = {
    container: {},
    relatedAttestations: {
      marginTop: 8,
    },
  };

  useEffect(() => {
    const provider = getReadOnlyProvider();
    const contract = new Contract(EASContractAddress, EASabi, provider);

    async function getRelated() {
      const relatedUUIDS = await contract.getRelatedAttestationUUIDs(
        UUID,
        0,
        100,
        true
      );
      const tmpRelatedAttestations = await getAttestationsForUUIDS(
        relatedUUIDS,
        contract
      );
      setRelatedAttestations(tmpRelatedAttestations);
    }

    async function getAttestationData() {
      if (!loadedAttestationData) {
        const attestation = await getAttestation(UUID);

        setLoadedAttesttationData(attestation);
      }
    }

    getAttestationData();
    getRelated();
  }, []);

  return (
    <Dialog
      isVisible={true}
      body={
        <div style={styles.container}>
          <div>
            {loadedAttestationData ? (
              <AttestationTable
                whichAddress={whichAddress}
                attestations={[loadedAttestationData]}
                noHeader={true}
                showDetails={true}
              />
            ) : null}

            <div style={styles.relatedAttestations}>
              <SectionHeader
                title={"Secondary Attestations"}
                iconComponent={FaList}
              />
              {relatedAttestations && relatedAttestations.length ? (
                <>
                  <AttestationTable
                    showMiniFrom={true}
                    whichAddress={"from"}
                    attestations={relatedAttestations}
                  />
                </>
              ) : !relatedAttestations?.length &&
                relatedAttestations !== null ? (
                <div>No related attestations found.</div>
              ) : (
                <div>
                  <LoadingForDialog
                    position={'flex-start'}
                    text={"Loading secondary attestations..."}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      }
      title={"Attestation details"}
      onClose={onClose}
    />
  );
}
