import React, { useEffect, useState } from "react";
import { Dialog } from "../Dialog";
import { attestationTypes } from "../../attestationTypes";
import Well from "../Well";
import { ConfirmOrCancel } from "../ConfirmOrCancel";
import { Contract, ethers } from "ethers";
import EASabi from "../../abis/EASabi.json";
import { LoadingForDialog } from "../LoadingForDialog";
import { EASContractAddress } from "../../utils/config";
import { getDefaultExpiration, getSigningProvider } from "../../utils/Utils";
import { CostEstimate } from "../CostEstimate";

// Null attestation
export default function AO_1({ isVisible, onClose, attestToAddress }) {
  const signer = getSigningProvider();
  const contract = new Contract(EASContractAddress, EASabi, signer);

  const [loading, setLoading] = useState(false);
  const attestationType = attestationTypes[1];
  const [estimatedCost, setEstimatedCost] = useState(null);

  useEffect(() => {
    async function estimateCost() {
      const estimate = await contract.estimateGas.attest(
        attestToAddress,
        1,
        getDefaultExpiration(),
        ethers.constants.HashZero,
        ethers.constants.HashZero
      );

      const formattedEstimate = Number(
        ethers.utils.formatUnits(estimate, "gwei")
      );

      setEstimatedCost(formattedEstimate);
    }

    estimateCost();
  }, []);

  const styles = {
    actions: {
      display: "flex",
      justifyContent: "flex-end",
    },
    cancel: {
      marginRight: 8,
    },
  };
  return (
    <Dialog
      isVisible={isVisible}
      onClose={onClose}
      title={"Create null attestation"}
      body={
        <div style={styles.container}>
          <Well>{attestationType.description}</Well>
          <CostEstimate estimate={estimatedCost}/>
          {!loading ? (
            <ConfirmOrCancel
              onCancel={onClose}
              onConfirm={async () => {
                try {
                  const tx = await contract.attest(
                    attestToAddress,
                    1,
                    getDefaultExpiration(),
                    ethers.constants.HashZero,
                    ethers.constants.HashZero
                  );

                  setLoading(true);

                  setTimeout(async () => {
                    await tx.wait();

                    setLoading(false);
                    onClose();
                  }, 50);
                } catch (e) {
                  console.log("txerror", e);
                }
              }}
            />
          ) : (
            <LoadingForDialog />
          )}
        </div>
      }
    />
  );
}
