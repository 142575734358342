import React, {useEffect, useState} from 'react';
import {ethers, utils} from "ethers";
import {getAttestation, getReadOnlyProvider, getReceivedAttestations, getSentAttestations} from "../utils/Utils";
import {rootURL} from "../utils/config";
import axios from 'axios';

export default function useAttestations(address, previousAddress) {
  const [newReceived, setNewReceived] = useState(null);
  const [receivedAttestations, setReceivedAttestations] = useState(null);
  const [sentAttestations, setSentAttestations] = useState(null);


  useEffect(() => {
    if (newReceived && receivedAttestations) {
      setNewReceived(null);
      const duplicate = receivedAttestations.find(
        (att) => att.UUID === newReceived.UUID
      );

      if (!duplicate) {
        setReceivedAttestations([newReceived, ...receivedAttestations]);
      }
    }
  }, [newReceived, receivedAttestations]);

  useEffect(() => {
    if (previousAddress === address) {
      return;
    }

    let provider;

    const attestSig = ethers.utils.id(
      "Attested(address,address,bytes32,uint256)"
    );
    const revokeSig = ethers.utils.id(
      "Revoked(address,address,bytes32,uint256)"
    );

    const topicSets = [[attestSig, revokeSig]];

    async function getAttestations(dontClear) {
      if (!dontClear) {
        setReceivedAttestations(null);
        setSentAttestations(null);
      }

      try {

        const res = await axios.get(`${rootURL}/getAttestations/${address}`)
        setReceivedAttestations(res.data.received);
        setSentAttestations(res.data.sent);
      } catch (e) {
        console.log("getAttestations error", e);
      }
    }

    getAttestations();

    try {
      provider = getReadOnlyProvider();

      provider.on(topicSets, (log, event) => {
        if (utils.hexZeroPad(address, 32).toLowerCase() === log.topics[1]) {
          setTimeout(async () => {
            console.log("adding from event");
            if (log.topics[0] === attestSig) {
              const UUID = log.topics[3];
              const attestation = await getAttestation(UUID);

              setNewReceived(attestation);
            } else {
              getAttestations(true);
            }
          }, 500);
        }
      });
    } catch (e) {
      console.log("Provider error", e);
    }

    return () => {
      if (provider) {
        provider.off(topicSets);
      }
    };
  }, [address]);

  return [receivedAttestations, sentAttestations];
}
